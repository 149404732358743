import React from 'react';
import Layout from '../components/Layout';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { graphql } from 'gatsby';
import PageHeader from '../components/PageHeader';
import DetailHeader from '../components/DetailHeader';
import Footer from '../components/Footer';
import { Box } from '@3pc/layout-components-react';
import styled from '@emotion/styled';
import { colors } from '../styles/colors';
import { footerHeight } from '../components/Footer';
import { maxWidthVals } from '../styles/container';
import ResponsiveImage from '../base/ResponsiveImage';
import { replaceUmlaute } from '../utils';

const ContentContainer = styled.div`
  background: ${colors.white};
  padding: 20px 20px 50px 20px;
  margin: 0 auto 10px auto;
  width: calc(100% - 40px);
  max-width: ${maxWidthVals.uiMaxWidth};
  border-radius: 4px;
  position: relative;
  z-index: 0;
  margin-bottom: ${footerHeight.footerMobile};

  &::before {
    content: '';
    position: absolute;
    left: -1px;
    top: -1px;
    width: calc(100% + 2px);
    height: 100%;
    background: linear-gradient(180deg, ${colors.blue} 0%, ${colors.white} 85%);
    border-radius: 4px;
    z-index: -2;
  }

  &::after {
    content: '';
    position: absolute;
    left: 1px;
    top: 1px;
    width: calc(100% - 2px);
    height: 100%;
    background: ${colors.white};
    border-radius: 3px;
    z-index: -1;
  }

  p {
    font-size: 0.875em;
    line-height: 1.313em;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  ol {
    list-style: none;
    counter-reset: my-awesome-counter;
  }
  ol li {
    counter-increment: my-awesome-counter;
    display: flex;
    margin-bottom: 20px;
    font-size: 0.875em;
    line-height: 1.313em;
  }
  ol li::before {
    content: '(' counter(my-awesome-counter) ')';
    display: flex;
    white-space: pre;
    font-weight: bold;
    margin-right: 15px;
    font-size: 0.875em;
    line-height: 1.313em;
  }

  p a {
    word-break: break-word;
  }
`;

export const query = graphql`
  query($slug: String!) {
    mdx(slug: { eq: $slug }) {
      frontmatter {
        slug
        title
        reference
      }
      body
    }
    image: file(relativePath: { eq: "12.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          aspectRatio: 3.10303
          transformOptions: { grayscale: true, cropFocus: NORTH }
          placeholder: BLURRED
        )
      }
    }
    mobileImage: file(relativePath: { eq: "12.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          aspectRatio: 3.10303
          transformOptions: { grayscale: true, cropFocus: NORTH }
          placeholder: BLURRED
        )
      }
    }
  }
`;

const LexiconDetailPage = (data) => {
  /* Create Array of References */
  const headerInfoItems = data.data.mdx.frontmatter.reference;
  const references = headerInfoItems ? headerInfoItems.split(' - ') : '';

  /* Create Array of URLs */
  const urls = [];
  references.length &&
    references.forEach((reference) => {
      if (reference.split(' ').length === 1) {
        urls.push(replaceUmlaute(reference).toLowerCase());
      } else {
        urls.push(
          replaceUmlaute(reference)
            .split(' ')
            .map((el) => el.toLowerCase())
            .join('-')
        );
      }
    });

  /* Add Arrays into Object to be able to use the data-set easily */
  const headerInfo = { references, urls };

  /* Define image sources */
  const defaultImage = data.data.image.childImageSharp.gatsbyImageData;
  const mobileImage = data.data.mobileImage.childImageSharp.gatsbyImageData;
  const altTag =
    'Farbfotografie von Schülern und Schülerinnen in einem Plenarsaal während eines Workshops zur Verfassungsänderung im Jahr 2018';
  return (
    <>
      <PageHeader
        headerTitle={data.data.mdx.frontmatter.title}
        pageType={'detail-page'}
      />
      <Layout>
        <Box as="article" position="relative">
          <ResponsiveImage
            defaultImage={defaultImage}
            mobileImage={mobileImage}
            altTag={altTag}
          />

          <Box>
            <DetailHeader
              headerTitle={data.data.mdx.frontmatter.title}
              headerInfo={headerInfo}
              slug={data.data.mdx.frontmatter.slug}
            />
          </Box>

          <ContentContainer>
            <MDXRenderer>{data.data.mdx.body}</MDXRenderer>
          </ContentContainer>
        </Box>
      </Layout>
      <Footer />
    </>
  );
};

export default LexiconDetailPage;
